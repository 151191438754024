import React, { useState } from 'react';

export default function SMSOptions({ username, usertoken, status, leaguemembers }) {
    const [token, setToken] = useState(usertoken);
    const [savestatus, setSavestatus] = useState();
    const id = leaguemembers.find(({ email }) => email === username).id;
    const pval = leaguemembers.find(({ email }) => email === username).phone;
    const oval = leaguemembers.find(({ email }) => email === username).sms;
    const [cval, setCval] = useState(oval);
    const pe = process.env;
    const sf = pe.REACT_APP_SPACE_FILLER;
    const postsmsoptionurl = pe.REACT_APP_SERVICE_URL + pe.REACT_APP_UPDATE_SMS_URL;
    const idtkn = pe.REACT_APP_ID_TOKEN;
    const valtkn = pe.REACT_APP_VALUE_TOKEN;

    let bposterror = false;

    //useEffect(() => {
        //const pval = leaguemembers.find(({ email }) => email === username).phone;
        //setPhone(pval);
    //},[])

    const handleChange = (e) => {
        setCval(e.checked);
    }

    async function saveOptions() {
        let url = postsmsoptionurl
            .replace(idtkn, id)
            .replace(valtkn, valtkn)
            .replaceAll(" ", sf);

        //console.log("user auth attempt: " + localuser)
        status.addToQueue();

        setSavestatus("Options saved.")

        // var f = await fetch(postsheduleurl,
        //     {
        //         method: "POST",
        //         mode: "cors",
        //         headers: { 'Authorization': 'Bearer ' + token, "Content-Type": "application/json" },
        //         body: JSON.stringify(schedule, null, 2)
        //     })
        //     .then(res => {
        //         if (res.status !== 200) bposterror = true;
        //         return res.json();
        //     })
        //     .then(data => {
        //         setSavestatus(data.message);
        //         if (bposterror) {

        //         } else {
        //             //console.log("user: " + localuser + ", pwd: " + localpassword)
        //             console.log(data);
        //         }
        //     })
        //     .catch(error => {
        //         setSavestatus(error.toString());
        //         console.log(error.toString());
        //     });

        status.removeFromQueue();
    }

    async function saveOption() {
        //console.log("user auth attempt: " + localuser)
        status.addToQueue();

        let url = postsmsoptionurl
            .replaceAll(" ", sf);

        let option = {id: id, bval: cval};

        var f = await fetch(postsmsoptionurl,
            {
                method: "POST",
                mode: "cors",
                headers: { 'Authorization': 'Bearer ' + token, "Content-Type": "application/json" },
                body: JSON.stringify(option, null, 2)
            })
            .then(res => {
                if (res.status !== 200) bposterror = true;
                return res.json();
            })
            .then(data => {
                setSavestatus(data.message);
                if (bposterror) {

                } else {
                    //console.log("user: " + localuser + ", pwd: " + localpassword)
                    console.log(data);
                }
            })
            .catch(error => {
                setSavestatus(error.toString());
                console.log(error.toString());
            });

        status.removeFromQueue();
    }
    
    return (
        <div>
            {username && !savestatus &&
                <div>
                    <div>
                        <br />
                        <br />
                        <table className='pokertableboard'>
                            <tbody>
                                <tr>
                                    <td className='items' align='center' colSpan={2}>Donkleague Phone :&nbsp;
                                        <select id="selecteddate">
                                            <option value="+18449922574">+18449922574</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='items' align='center' colSpan={2}>Enter Preferred Contact Phone :&nbsp;
                                        <input type={Text} defaultValue={pval}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='items' align='center' colSpan={2}>SMS Subscription Options :&nbsp;
                                        <table>
                                            <tr>
                                                <td>All</td>
                                                <td><input type={'checkbox'} checked={cval} onChange={(e) => handleChange(e.target)}></input></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>Or Any Combination of Following:</td>
                                            </tr>
                                            <tr>
                                                <td>Scheduling</td>
                                                <td><input type={'checkbox'}></input></td>
                                            </tr>
                                            <tr>
                                                <td>Game Results</td>
                                                <td><input type={'checkbox'}></input></td>
                                            </tr>
                                            <tr>
                                                <td>Community Board</td>
                                                <td><input type={'checkbox'}></input></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Text "COMMANDS:?" to the number above for additional<br></br>
                                    instructions on available SMS functions.
                                    </td>
                                </tr>
                                <tr>
                                    <td align='center'>
                                        <button onClick={saveOption}>Save</button>
                                    </td>
                                    <td align='center'>
                                        <button>Cancel</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            {savestatus &&
                <div><span className='resultsmsg'>{savestatus}</span></div>
            }
        </div>
    );
}